import React from "react";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";

const PaidFeatures = () => {
  return (
    <>
      <section id="about-us-area-home4" className="py100 bg-color">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className=" ">
                <h2 className="heading-section-color text-centre">
                  Paid Features
                </h2>
                <p className="py-3 ">
                  See how all the paid features work and try them out for free!
                </p>
                <div className=" d-flex justify-content-center">
                  {" "}
                  <Link to="/login" className="btn btn-theme pt30">START FREE TRIAL</Link>
                </div>
              </div>
              <div className="mx-auto">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image07.jpg")}
                  className="my-3  py-5"
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">ADD TIME FOR OTHERS</span>
                <h2 className="py-2">Log time on behalf of your team</h2>
                <p className="my-2">
                  Add time on other people's behalf right from the report. You
                  can add time either for a single user, or for a whole group at
                  once. Or alternatively, switch to your teammate's timesheet
                  and quickly populate their timesheet for them. This way,
                  you'll no longer have to wait for someone to add the missing
                  time.
                </p>
                <div className="mx-auto text-center">
                  {" "}
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image09.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">HIDE TIME & PAGES</span>
                <h2 className="py-2">Prevent users from seeing everything</h2>
                <p className="my-2">
                  Hide Projects, Team, and Reports from regular users. Prevent
                  people from seeing other people's time entries.
                </p>
                <div className="mx-auto text-center">
                  {" "}
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image06.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">REQUIRED FIELDS</span>
                <h2 className="py-2">Prevent incomplete time entries</h2>
                <p className="my-2">
                  Make your most important fields required so no one can create
                  entries with missing information (like description, project,
                  task, or a custom field). Once entries are properly
                  categorized, you'll no longer have to spend hours fixing other
                  people's timesheets.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image03.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">BULK EDIT</span>
                <h2 className="py-2">
                  Update multiple entries, projects, and users
                </h2>
                <p className="my-2">
                  Select all the time entries, users, or projects you wish to
                  update and edit their properties. Perfect for record keeping,
                  like when you need to mark entries as invoiced, move them to a
                  different project, mark projects as private, adding same
                  custom field info to multiple users, etc.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image02.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">DECIMAL FORMAT</span>
                <h2 className="py-2">See and export time in decimal format</h2>
                <p className="my-2">
                  Switch to decimal duration format, and all time will be
                  displayed, entered, and exported in decimals (instead of the
                    eClock.Work format).
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image07.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">TIME AUDIT</span>
                <h2 className="py-2">Clean up your reports</h2>
                <p className="my-2">
                  Find all uncategorized entries or entries that are
                  suspiciously short/long, and fix them. Once everything is
                  properly labeled and cleaned up, you can lock them and share
                  the report with clients.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">CUSTOMIZE EXPORT</span>
                <h2 className="py-2">Add logo and remove columns</h2>
                <p className="my-2">
                  When exporting reports or projects, choose which columns you
                  don't need in the CSV/Excel, or what you wish to be displayed
                  on the PDF report (data, charts, different report name,
                  company logo, etc.).
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image06.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">PROJECT TEMPLATES</span>
                <h2 className="py-2">Set up a lot of identical projects</h2>
                <p className="my-2">
                  Once you have a project with tasks set up, set it as a
                  template. Then, every time you create a new project, you'll be
                  able to select the template and have all the tasks and
                  settings copied over to the new project. Perfect if you have a
                  lot of similar projects for different clients.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image10.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">HISTORICAL RATES</span>
                <h2 className="py-2">Update all your past hourly rates</h2>
                <p className="my-2">
                  When you change an hourly rate, you can choose if the new rate
                  applies to only newly created time entries from now on, if you
                  wish to overwrite previous rate on existing time entries, or
                  if you wish to apply the new rate from a certain date forward.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image05.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">EDIT PROFILE</span>
                <h2 className="py-2">
                  EDIT PROFILE Change your teammate's information
                </h2>
                <p className="my-2">
                  Edit your team member's profile information (like their name
                  or week start) directly yourself, without having to wait for
                  them to do it.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image06.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">IMPORT TIME</span>
                <h2 className="py-2">Import your old timesheets from a file</h2>
                <p className="my-2">
                  Prepare a CSV file in Excel, and then import it. eClock.Work will
                  analyze the file, create any missing projects and clients, and
                  import all the time entries in it for both you and your team.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">KIOSK PIN</span>
                <h2 className="py-2">eClock.Work in with PIN</h2>
                <p className="my-2">
                  When clocking in and out via the kiosk, require employees to
                  sign in with their personal 4-digit PIN code. You can also
                  eClock.Work in for others using the kiosk's secret Universal PIN
                  code.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image10.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">BREAKS</span>
                <h2 className="py-2">Track break time</h2>
                <p className="my-2">
                  Enable breaks so people can take a break while eClock.Work in and
                  track how much time they spend on breaks.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image09.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">FAVORITE ENTRIES</span>
                <h2 className="py-2">Pin most used timers</h2>
                <p className="my-2">
                  Pin existing time entries to the top of the Time Tracker page
                  so you can quickly start timers for your most common
                  activities.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image05.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image08.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">ADD TIME FOR OTHERS</span>
                <h2 className="py-2">Log time on behalf of your team</h2>
                <p className="my-2">
                  Add time on other people's behalf right from the report. You
                  can add time either for a single user, or for a whole group at
                  once. Or alternatively, switch to your teammate's timesheet
                  and quickly populate their timesheet for them. This way,
                  you'll no longer have to wait for someone to add the missing
                  time.
                </p>
                <div className="mx-auto text-center">
                  {" "}
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image09.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">HIDE TIME & PAGES</span>
                <h2 className="py-2">Prevent users from seeing everything</h2>
                <p className="my-2">
                  Hide Projects, Team, and Reports from regular users. Prevent
                  people from seeing other people's time entries.
                </p>
                <div className="mx-auto text-center">
                  {" "}
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image06.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">REQUIRED FIELDS</span>
                <h2 className="py-2">Prevent incomplete time entries</h2>
                <p className="my-2">
                  Make your most important fields required so no one can create
                  entries with missing information (like description, project,
                  task, or a custom field). Once entries are properly
                  categorized, you'll no longer have to spend hours fixing other
                  people's timesheets.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image03.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">BULK EDIT</span>
                <h2 className="py-2">
                  Update multiple entries, projects, and users
                </h2>
                <p className="my-2">
                  Select all the time entries, users, or projects you wish to
                  update and edit their properties. Perfect for record keeping,
                  like when you need to mark entries as invoiced, move them to a
                  different project, mark projects as private, adding same
                  custom field info to multiple users, etc.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image02.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">DECIMAL FORMAT</span>
                <h2 className="py-2">See and export time in decimal format</h2>
                <p className="my-2">
                  Switch to decimal duration format, and all time will be
                  displayed, entered, and exported in decimals (instead of the
                    eClock.Work format).
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image07.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">TIME AUDIT</span>
                <h2 className="py-2">Clean up your reports</h2>
                <p className="my-2">
                  Find all uncategorized entries or entries that are
                  suspiciously short/long, and fix them. Once everything is
                  properly labeled and cleaned up, you can lock them and share
                  the report with clients.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">CUSTOMIZE EXPORT</span>
                <h2 className="py-2">Add logo and remove columns</h2>
                <p className="my-2">
                  When exporting reports or projects, choose which columns you
                  don't need in the CSV/Excel, or what you wish to be displayed
                  on the PDF report (data, charts, different report name,
                  company logo, etc.).
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image06.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">PROJECT TEMPLATES</span>
                <h2 className="py-2">Set up a lot of identical projects</h2>
                <p className="my-2">
                  Once you have a project with tasks set up, set it as a
                  template. Then, every time you create a new project, you'll be
                  able to select the template and have all the tasks and
                  settings copied over to the new project. Perfect if you have a
                  lot of similar projects for different clients.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image10.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">HISTORICAL RATES</span>
                <h2 className="py-2">Update all your past hourly rates</h2>
                <p className="my-2">
                  When you change an hourly rate, you can choose if the new rate
                  applies to only newly created time entries from now on, if you
                  wish to overwrite previous rate on existing time entries, or
                  if you wish to apply the new rate from a certain date forward.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image05.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">EDIT PROFILE</span>
                <h2 className="py-2">
                  EDIT PROFILE Change your teammate's information
                </h2>
                <p className="my-2">
                  Edit your team member's profile information (like their name
                  or week start) directly yourself, without having to wait for
                  them to do it.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image06.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">IMPORT TIME</span>
                <h2 className="py-2">Import your old timesheets from a file</h2>
                <p className="my-2">
                  Prepare a CSV file in Excel, and then import it. eClock.Work will
                  analyze the file, create any missing projects and clients, and
                  import all the time entries in it for both you and your team.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">KIOSK PIN</span>
                <h2 className="py-2">eClock.Work in with PIN</h2>
                <p className="my-2">
                  When eClock.Work in and out via the kiosk, require employees to
                  sign in with their personal 4-digit PIN code. You can also
                  eClock.Work in for others using the kiosk's secret Universal PIN
                  code.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image10.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">BREAKS</span>
                <h2 className="py-2">Track break time</h2>
                <p className="my-2">
                  Enable breaks so people can take a break while eClock.Work in and
                  track how much time they spend on breaks.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image09.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <span className="">FAVORITE ENTRIES</span>
                <h2 className="py-2">Pin most used timers</h2>
                <p className="my-2">
                  Pin existing time entries to the top of the Time Tracker page
                  so you can quickly start timers for your most common
                  activities.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image05.jpg")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <div className="margintop">
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image08.png")}
                    className="pyimage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaidFeatures;
