import React from "react";
//  Import Img
import { FaCircleArrowRight } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";


import { Link } from "react-router-dom";

const MoreFeatures = () => {
  return (
    <>
      <section id="" className="">
        <div className="container border-bottom">
          <div className="py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Hourly Rates
                      </h3>
                      <p>
                        Define hourly rates for tasks and projects, and see
                        earnings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Projects
                      </h3>
                      <p>
                        Break down project into tasks, and categorize them by
                        client.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Reports
                      </h3>
                      <p>
                        See breakdown of all time by custom date and dimension.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Export
                      </h3>
                      <p>
                        Customize and export data from reports (Excel, PDF,
                        CSV).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Invoicing
                      </h3>
                      <p>Create invoices based on tracked billable hours.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Rounding
                      </h3>
                      <p>Round time up, down, or to nearest X minutes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Costs & Profit
                      </h3>
                      <p>
                        Compare what you bill client vs what you pay your team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Estimates
                      </h3>
                      <p>
                        Set time estimates on projects and tasks and track
                        progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Expenses
                      </h3>
                      <p>
                        Record and invoice project and member fixed-fee
                        expenses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h2 className="pb10 ">Related</h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6 border-start related-wrapper py-3">
                <div className="single-feature ">
                  <div className="media">
                    <div className="media-body pl30 animation-img-two">
                      <h3 className="hover-color">
                        Reports
                        <span className="iconstyle-arrow-first hover-color">
                          {" "}
                          <FaCircleArrowRight />
                        </span>
                      </h3>
                      <p>Plan your employees future.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 related-wrapper py-3">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30  animation-img-two">
                      <h3 className="hover-color">
                        Time Off{" "}
                        <span className="iconstyle-arrow ">
                          {" "}
                          <FaCircleArrowRight />
                        </span>
                      </h3>
                      <p>See whrer and when your employees eClock.Work in .</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoreFeatures;
