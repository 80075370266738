import React from "react";
//  Import Img
import { FaCircleArrowRight } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";

import { Link } from "react-router-dom";

const MoreFeaturesScheduling = () => {
  return (
    <>
      <section id="" className="">
        <div className="container border-bottom">
          <div className="py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Time Breakdown
                      </h3>
                      <p>
                        Segment time by project and user, then export the data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Project Costs
                      </h3>
                      <p>
                        Document project fees, mileage, and additional
                        expenditures.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Project Financial Planning
                      </h3>
                      <p>
                        Establish project estimates and budget, and monitor
                        progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Weekly Activity Log
                      </h3>
                      <p>Record time and activities in a weekly log.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Progress Monitoring
                      </h3>
                      <p>
                        Monitor advancement using tracked and estimated time and
                        budget data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Project Predictions
                      </h3>
                      <p>
                        Predict project completion based on the current
                        schedule.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h2 className="pb10 ">Related</h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6 border-start related-wrapper py-3">
                <div className="single-feature ">
                  <div className="media">
                    <div className="media-body pl30 animation-img-two">
                      <h3 className="hover-color">
                        Work Activity Overview
                        <span className="iconstyle-arrow-first hover-color">
                          {" "}
                          <FaCircleArrowRight />
                        </span>
                      </h3>
                      <p>
                        Review details on who worked on tasks and their
                        durations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 related-wrapper py-3">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30  animation-img-two">
                      <h3 className="hover-color">
                        Leave Management{" "}
                        <span className="iconstyle-arrow ">
                          {" "}
                          <FaCircleArrowRight />
                        </span>
                      </h3>
                      <p>
                        Monitor holidays, leave days, and remaining balances.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoreFeaturesScheduling;
