import React, { useState } from "react";
import img1 from "../../assets/img/coming-soon.jpg";
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";

// Modal
// Video Modal
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/css/modal-video.min.css';

const FirstScheduling = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 heading-section-color">Scheduling</h2>
                <h4>
                Organize schedules and allocate tasks to team members.






                </h4>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <Link to="/login" className="btn btn-theme mt-3 mr-2">
                START TRACKING TIME
              </Link>
              <Link to="#!" className="btn btn-theme mt-3" onClick={() => setOpen(true)}>
                WATCH VIDEO
                <span className="iconstyle">
                  <FaArrowAltCircleRight />
                </span>{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
      {/* Watch video Start */}
      <ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
      {/* Watch video End */}

      {/* Testimonial Area */}
      <section id="testimonial-area" className="py-5  bg-color">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 text-center">
              <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image08.png")}
                  className="mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstScheduling;
